<template>
    <div id="users-list">
        {{ getTradC('minutesBefore', 10) }}
        <v-row class="mb-5">
            <v-col cols="12" sm="6" md="3">
                <v-card>
                    <v-card-text class="d-flex align-center justify-space-between pa-4">
                        <div>
                            <h2 class="font-weight-semibold mb-1">
                                {{ totalUsers }}
                            </h2>
                            <span>{{ getTrad('List.totalUsers') }}</span>
                        </div>

                        <v-avatar color="primary" :class="`v-avatar-light-bg primary--text`">
                            <v-icon size="25" color="primary" class="rounded-0">
                                {{ mdiAccountOutline }}
                            </v-icon>
                        </v-avatar>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card>
                    <v-card-text class="d-flex align-center justify-space-between pa-4">
                        <div>
                            <h2 class="font-weight-semibold mb-1">
                                {{ extra.groupByStatus.active }}
                            </h2>
                            <span>{{ getTrad('List.activeUsers') }}</span>
                        </div>

                        <v-avatar color="green" :class="`v-avatar-light-bg green--text`">
                            <v-icon size="25" color="green" class="rounded-0">
                                {{ mdiAccountCheckOutline }}
                            </v-icon>
                        </v-avatar>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card>
                    <v-card-text class="d-flex align-center justify-space-between pa-4">
                        <div>
                            <h2 class="font-weight-semibold mb-1">
                                {{ extra.groupByStatus.pending }}
                            </h2>
                            <span>{{ getTrad('List.pendingUsers') }}</span>
                        </div>

                        <v-avatar color="orange" :class="`v-avatar-light-bg orange--text`">
                            <v-icon size="25" color="orange" class="rounded-0">
                                {{ mdiAccountRemoveOutline }}
                            </v-icon>
                        </v-avatar>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card>
                    <v-card-text class="d-flex align-center justify-space-between pa-4">
                        <div>
                            <h2 class="font-weight-semibold mb-1">
                                {{ extra.groupByStatus.disabled }}
                            </h2>
                            <span>{{ getTrad('List.disabledUsers') }}</span>
                        </div>

                        <v-avatar color="red" :class="`v-avatar-light-bg red--text`">
                            <v-icon size="25" color="red" class="rounded-0">
                                {{ mdiAccountOffOutline }}
                            </v-icon>
                        </v-avatar>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card>
            <v-card-title> {{ getTrad('List.searchFilter') }} </v-card-title>
            <v-row class="px-2 ma-0">
                <v-col cols="12" sm="4">
                    <v-select
                        @change="getUsers"
                        :items="roleOptions"
                        item-text="name"
                        item-value="id"
                        v-model="selectedRole"
                        :placeholder="getTrad('List.selectRole')"
                        outlined
                        dense
                        clearable
                        hide-details></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-select @change="getUsers" :items="statuses" v-model="selectedStatus" :placeholder="getTrad('List.selectStatus')" outlined dense clearable hide-details></v-select>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-card-text class="d-flex align-center flex-wrap pb-0">
                <v-text-field v-model="searchText" @change="getUsers" :placeholder="getTrad('List.search')" outlined hide-details dense class="user-search me-3 mb-4"></v-text-field>
                <v-spacer></v-spacer>
                <div class="d-flex align-center flex-wrap">
                    <v-btn color="primary" class="mb-4 me-3" @click.stop="toAddUser">
                        <v-icon>{{ mdiPlus }}</v-icon>
                        <span>{{ getTrad('List.addUser') }}</span>
                    </v-btn>
                </div>
            </v-card-text>
            <v-card-text v-if="selectedRows.length > 0">
                <v-btn color="red" @click="toDeleteSelected">
                    <span class="white--text">{{ getTrad('List.deleteSelected') }}</span>
                </v-btn>
            </v-card-text>
            <v-data-table
                :page.sync="query.pagination.page"
                :items-per-page.sync="query.pagination.pageSize"
                :server-items-length="serverUsers"
                checkbox-color="primary"
                v-model="selectedRows"
                :headers="tableColumns"
                :items="users"
                :loading="isLoading"
                :footer-props="footerProps"
                show-select>
                <template #[`item.name`]="{ item }">
                    <div class="d-flex align-center">
                        <v-avatar :color="item.picture ? '' : 'primary'" :class="item.picture ? '' : 'v-avatar-light-bg primary--text'" size="32">
                            <v-img v-if="item.picture" :src="resolveImg(item.picture, 'thumbnail')"></v-img>
                            <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span>
                        </v-avatar>

                        <div class="d-flex flex-column ms-3">
                            <router-link
                                :to="{ name: routeName('Users-Details'), params: { id: item.id } }"
                                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                {{ item.name }}
                            </router-link>
                            <small>@{{ item.username }}</small>
                        </div>
                    </div>
                </template>
                <template #[`item.status`]="{ item }">
                    <v-chip small :color="resolveUserStatus(item.status)" :class="`${resolveUserStatus(item.status)}--text`" class="v-chip-light-bg font-weight-semibold text-capitalize">
                        {{ item.status }}
                    </v-chip>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="toEditItem(item)"> {{ mdiEye }} </v-icon>
                    <v-icon small class="mr-2" @click="toEditItem(item)"> {{ mdiPencil }} </v-icon>
                    <v-icon small @click="toDeleteItem(item)"> {{ mdiDeleteOutline }} </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <Form v-model="isFormActive" @action="getUsers" :roleOptions="roleOptions" :statusOptions="statuses" :action="formAction" :user="itemToEdit" />

        <v-dialog max-width="290" v-model="isDialogActive">
            <v-card>
                <v-card-title>{{ getTrad('List.deleteUser') }}</v-card-title>
                <v-card-text>{{ getTrad('List.deleteUserConfirm') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="isDialogActive = false"> {{ getTrad('cancel') }} </v-btn>

                    <v-btn color="red" text @click="deleteItem"> {{ getTrad('delete') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="290" v-model="isDeleteManyActive">
            <v-card>
                <v-card-title>{{ getTrad('List.deleteManyUsers') }}</v-card-title>
                <v-card-text>{{ getTrad('List.deleteSelectedConfirm') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="isDeleteManyActive = false"> {{ getTrad('cancel') }} </v-btn>

                    <v-btn color="red" text @click="deleteManyUsers"> {{ getTrad('delete') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mdiAccountOutline, mdiAccountCheckOutline, mdiAccountRemoveOutline, mdiPlus, mdiAccountOffOutline, mdiDeleteOutline, mdiPencil, mdiEye } from '@mdi/js';
    import { getUsers, deleteUser, deleteManyUsers } from '../../api/users';
    import getTrads from '../../i18n/getTrad';
    import _ from 'lodash';
    import { avatarText } from '@/@core/utils/filter';
    import { resolveImg } from '@/utils/img';
    import { getRoles } from '../../api/roles';
    import { routeName } from '../../constants';

    import Form from './Form.vue';

    export default {
        name: 'Users-List',
        mixins: [getTrads],
        components: {
            Form,
        },
        data() {
            return {
                formAction: 'add',
                isDialogActive: false,
                isDeleteManyActive: false,
                isFormActive: false,
                isLoading: false,
                routeName,
                resolveImg,
                avatarText,
                mdiAccountOutline,
                mdiAccountCheckOutline,
                mdiAccountRemoveOutline,
                mdiPlus,
                mdiAccountOffOutline,
                mdiDeleteOutline,
                mdiPencil,
                mdiEye,

                footerProps: {
                    'items-per-page-options': [10, 20, 50],
                },

                users: [],
                query: {
                    filters: {},
                    populate: ['role', 'picture'],
                    extra: ['groupByStatus', 'getStatuses'],
                    pagination: {
                        page: 1,
                        pageSize: 10,
                    },
                },
                serverUsers: 0,
                searchText: '',
                itemToDelete: null,
                itemToEdit: null,

                roleOptions: [],
                extra: {
                    groupByStatus: {
                        active: 1,
                        disabled: 0,
                        pending: 0,
                    },
                    getStatuses: [],
                },
                selectedRole: null,
                selectedStatus: null,
                selectedRows: [],
                tableColumns: [
                    { text: 'user.user', value: 'name' },
                    { text: 'user.email', value: 'email' },
                    { text: 'user.role', value: 'role.name', sortable: false },
                    { text: 'user.user', value: 'status', sortable: false },
                    {
                        text: 'List.actions',
                        value: 'actions',
                        align: 'center',
                        sortable: false,
                    },
                ],
            };
        },
        watch: {
            'query.pagination': {
                handler() {
                    this.getUsers();
                },
                deep: true,
            },
        },
        created() {
            this.getUsers();
            this.getRoles();
            this.tableColumns.forEach((column) => {
                column.text = this.getTrad(column.text);
            });
        },
        computed: {
            totalUsers() {
                let total = 0;
                console.log(this.extra);
                Object.keys(this.extra.groupByStatus).forEach((key) => {
                    total += this.extra.groupByStatus[key];
                });
                return total;
            },
            statuses() {
                return this.extra.getStatuses.map((status) => {
                    return {
                        text: this.getTrad(`user.statuses.${status}`),
                        value: status,
                    };
                });
            },
            resolveUserStatus: () => (status) => {
                switch (status) {
                    case 'active':
                        return 'green';
                    case 'disabled':
                        return 'red';
                    case 'pending':
                        return 'orange';
                    default:
                        return 'primary';
                }
            },
            getQuery() {
                let query = _.assign({}, this.query);

                if (this.selectedRole) {
                    query.filters.role = this.selectedRole;
                } else {
                    delete this.query.filters.role;
                }

                if (this.selectedStatus) {
                    query.filters.status = this.selectedStatus;
                } else {
                    delete this.query.filters.status;
                }

                if (this.searchText) {
                    query.filters.$or = [
                        {
                            name: {
                                $containsi: this.searchText,
                            },
                        },
                        {
                            email: {
                                $containsi: this.searchText,
                            },
                        },
                    ];
                } else {
                    delete this.query.filters.$or;
                }
                return query;
            },
        },
        methods: {
            async getRoles() {
                await getRoles().then((response) => {
                    this.roleOptions = response.data.data;
                });
            },
            async getUsers() {
                this.isLoading = true;

                await getUsers(this.getQuery).then((response) => {
                    this.users = response.data.data.map(u => ({
                        id: u.id,
                        ...u.attributes
                    }));
                    // this.extra = response.data.meta.extra;
                    this.serverUsers = response.data.meta.pagination.total;
                });
                this.isLoading = false;
            },
            change() {
                this.$snackbar({
                    text: this.$t('shared.commonError'),
                    color: 'success',
                    timeout: 1000,
                });
            },
            getUsersCountByStatus(status) {
                return _.filter(this.users, { status }).length;
            },
            toAddUser() {
                this.isFormActive = !this.isFormActive;
                this.formAction = 'add';
            },
            toEditItem(item) {
                this.formAction = 'edit';
                this.itemToEdit = item;
                this.isFormActive = !this.isFormActive;
            },
            toDeleteItem(item) {
                this.itemToDelete = item;
                this.isDialogActive = true;
            },
            toDeleteSelected() {
                this.isDeleteManyActive = true;
            },
            async deleteManyUsers() {
                this.isDeleteManyActive = false;
                this.isLoading = true;
                await deleteManyUsers(this.selectedRows.map((user) => user.id))
                    .then(() => {
                        this.selectedRows = [];
                        this.$snackbar({
                            text: this.getTrad('List.deleteManySuccess'),
                            color: 'success',
                        });
                        this.getUsers();
                    })
                    .catch(() => {
                        this.$snackbar({
                            text: this.$t('shared.commonError'),
                            color: 'error',
                        });
                    });
                this.isLoading = false;
            },
            async deleteItem() {
                this.isDialogActive = false;
                this.isLoading = true;
                await deleteUser(this.itemToDelete.id)
                    .then(() => {
                        this.itemToDelete = null;
                        this.$snackbar({
                            text: this.getTrad('List.deleteSuccess'),
                            color: 'success',
                        });
                        this.getUsers();
                    })
                    .catch(() => {
                        this.$snackbar({
                            text: this.$t('shared.commonError'),
                            color: 'error',
                        });
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
